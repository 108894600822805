<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'บัญชีธนาคาร']" />
    <button @click="openModalCreate()" class="btn btn-success mb-2" v-show="canEdit">
      แบนบัญชีธนาคาร
    </button>
    <div class="card border-primary">
      <div class="card-header border-primary bg-primary text-white h5">
        ข้อมูลบัญชีธนาคารที่ถูกแบน
      </div>
      <div class="card-body">
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page-select="false"
          :items-per-page="20"
          :loading="tableLoading"
          :noItemsView="{
            noResults: 'ไม่พบข้อมูล',
            noItems: 'ไม่พบข้อมูล',
          }"
          :table-filter="true"
          hover
          pagination
          dark
          border
          addTableClasses="w-100"
        >
          <template #edit="{ item }">
            <td>
              <button class="btn btn-primary" @click="openModalEdit(item)" :disabled="!canEdit">
                <i class="fas fa-edit"></i>
              </button>
            </td>
          </template>
        </CDataTable>
      </div>
    </div>

    <b-modal
      id="modal-form"
      body-bg-variant="dark"
      body-text-variant="light"
      header-bg-variant="dark"
      header-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      title="แบนบัญชีธนาคาร"
    >
      <ValidationObserver
        ref="observer"
        tag="form"
        @submit.prevent="banBankAccount()"
      >
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="accountName">ชื่อบัญชี</label>
            <input
              class="form-control"
              id="accountName"
              v-model="form.bankAccountName"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required|numeric" v-slot="v">
            <label for="accountNumber">เลขบัญชี</label>
            <input
              class="form-control"
              id="accountNumber"
              v-model="form.bankAccountNumber"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="bankCode">ธนาคาร</label>
            <select class="form-control" id="bankCode" v-model="form.bankCode">
              <option value="" disabled>กรุณาเลือกธนาคาร</option>
              <option value="BBL">BBL ธ.กรุงเทพ</option>
              <option value="KBANK">KBANK ธ.กสิกรไทย</option>
              <option value="KTB">KTB ธ.กรุงไทย</option>
              <option value="SCB">SCB ธ.ไทยพาณิชย์</option>
              <option value="BAY">BAY ธ.กรุงศรีอยุธยา</option>
              <option value="CIMB">CIMB ธ.ซีไอเอ็มบี</option>
              <option value="GSB">GSB ธ.ออมสิน</option>
              <option value="KK">KIATNAKIN ธ.เกียรตินาคิน</option>
              <option value="LHB">LHBANK ธ.แลนด์ แอนด์ เฮ้าส์</option>
              <option value="SC">STANDARD ธ.สแตนดาร์ดชาร์เตอร์ด (ไทย)</option>
              <option value="TISCO">TISCO ธ.ทิสโก้</option>
              <option value="TTB">TTB ธ.ทหารไทยธนชาต</option>
              <option value="UOB">UOB ธ.ยูโอบี</option>
              <option value="BAAC">BAAC ธกส</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="note">เหตุผล</label>
            <input class="form-control" id="note" v-model="form.note" />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider rules="required" v-slot="v">
            <label for="status">สถานะ</label>
            <select class="form-control" id="status" v-model="form.status">
              <option value="ACTIVE">แบน</option>
              <option value="INACTIVE">ปลดแบน</option>
            </select>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="save()">บันทึก</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#ID",
          _style: "width: 1%",
          sorter: false,
        },
        {
          key: "bankAccountName",
          label: "ชื่อบัญชี",
          sorter: false,
        },
        {
          key: "bankAccountNumber",
          label: "เลขบัญชี",
          sorter: false,
        },
        {
          key: "bankNameTH",
          label: "ธนาคาร",
          filter: false,
          sorter: false,
        },
        {
          key: "note",
          label: "เหตุผล",
          filter: false,
          sorter: false,
        },
        {
          key: "updatedBy",
          label: "คนสร้าง",
          filter: false,
          sorter: false,
        },
        {
          key: "updatedAtFormatted",
          label: "เวลา",
          filter: false,
          sorter: false,
        },
        {
          key: "statusTH",
          label: "สถานะ",
          filter: false,
          sorter: false,
        },
        {
          key: "edit",
          label: "แก้ไข",
          filter: false,
          sorter: false,
        },
      ],
      items: [],
      tableLoading: true,
      form: {},
      isUpdate: false,
    }
  },
  methods: {
    openModalEdit(item) {
      this.form = item

      this.$bvModal.show("modal-form")
    },
    openModalCreate() {
      this.form = {}

      this.$bvModal.show("modal-form")
    },
    save() {
      if (this.form.id) {
        this.updateBanBankAccount()
      } else {
        this.banBankAccount()
      }
    },
    async updateBanBankAccount() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "put",
          url: "bank/ban",
          data: this.form,
        })

        await this.swal.success()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async banBankAccount() {
      let observer = this.$refs.observer
      let isValid = await observer.validate()
      if (!isValid) {
        this.focusError(observer)
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "post",
          url: "bank/ban",
          data: this.form,
        })

        await this.swal.success()

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
    async loadData() {
      this.tableLoading = true
      try {
        const { data } = await this.axios({
          method: "get",
          url: "bank/ban",
        })

        data.data?.forEach((item) => {
          item.updatedAtFormatted = this.$date(item.updatedAt).format(
            "HH:mm:ss DD/MM/YYYY"
          )
        })

        this.items = data.data

        this.tableLoading = false
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
  mounted() {
    this.loadData()
  },
  computed: {
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("bank_ban_edit") 
    }
  },
}
</script>